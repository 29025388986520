@import '../../../Styles/V2/Constants';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $gap-m;

  > div:first-child {
    gap: $gap-s;
    display: flex;
    flex-direction: column;
    width: 400px;
  }

  > div:last-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 400px;
  }
}