@import 'src/Styles/Constants.scss';

$resourceWidth: 250px;
$resourcePadding: 20px;

.container {
  cursor: pointer;
  padding: $resourcePadding;
  border-radius: 15px;
  border: 1px solid $darkGreen;
  display: grid;
  grid-template-rows: 60px 110px 150px;


  > .text {
    > :first-child {
      font-size: 25px;
      font-weight: 600;
      padding-bottom: 15px;
    }

    > :last-child {
      font-size: 15px;
    }
  }

}
