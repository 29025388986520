@import "src/Styles/V2/Constants";

$size: 400px;

.calenderButton {
  all: unset;
  height: 30px;
  cursor: pointer;

  &:focus {
    @include focused();
  }

  &.next {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.calendarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $padding-m
}

.calendarCell {
  width: $size;
  height: $size;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    @include focused();
  }

  cursor: pointer;

  font-size: 16px;

  border-radius: 100px;
  width: calc($size / 7);
  height: calc($size / 7);

  &:hover {
    color: $bgMenu;
    background-color: $accentEmphasis;
  }

  &[data-selected] {
    color: $bgMenu;
    background-color: $accentEmphasis;
  }

  &[data-outside-month] {
    display: none;
  }
}