@import '../../Styles/V2/Constants';

.box {
  background-color: $bgSurface;
  border-radius: 24px;
  padding: $padding-xl;
  
  @include shadow60;

  &.info {
    background-color: #FFFFBC;
  }

  &.small {
    padding: $padding-m;
  }
}