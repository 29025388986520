@import '/src/Styles/V2/Constants';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  > * {
    max-width: 840px;
  }
}

.headline {
  padding-top: $padding-l;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  gap: $padding-s;
}