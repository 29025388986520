

$overlay: #171717;
$borderColor: #F5F5F5;
$borderWidth: 2px;
$borderWidth-s: 1px;

// ACCENTS
$accentDefault: #B386CF;
$accentEmphasis: #445E54;
$accentMuted: #FFFFC9;
$accentSubtle: #ECEEFE;
$accentDisabled: #E0E0E0;

//BACKGROUNDS
$bgSurface: #FFFFFF;
$bgMuted: #E0E0E0;
$bgSubtle: #EEEEEE;
$bgCanvas: #F2F4EB;
$bgMenu: #e8eae1;

// FOREGROUNDS
$fgDefault: #153629;
$fgMuted: #616161;
$fgSubtle: #575757;
$fgOnDisabled: #757575;
$fgDisabled: #BDBDBD;
$fgAccent: #FFFFFF;

// SUCCESS
$successDefault: #11763D;
$successEmphasis: #18A957;
$successMuted: #5DC389;
$successSubtle: #E8F6EE;
$successOnSuccess: #FFFFFF;

// WARNING
$warningDefault: #B38327;
$warningEmphasis: #FDD343;
$warningMuted: #FFE4AF;
$warningSubtle: #FFF8EB;
$warningOnWarning: #171717;

// INFO
$infoDefault: #3142C4;
$infoEmphasis: #3D53F5;
$infoMuted: #8B98F9;
$infoSubtle: #D8DDFD;
$infoOnInfo: #FFFFFF;

// ERROR
$errorDefault: #9C0F2E;
$errorEmphasis: #FB5151;
$errorMuted: #E95C7B;
$errorSubtle: #FCE8EC;
$errorOnError: #FFFFFF;

// INPUTS
$inputBg: #FFFFFF;
$inputText: #424242;
$inputPlaceholder: #9E9E9E;
$inputBorder: #E0E0E0;
$inputBorderDisabled: #EEEEEE;
$inputBgDisabled: #E0E0E0;

$padding-xs: 8px;
$padding-s: 16px;
$padding-m: 24px;
$padding-l: 40px;
$padding-xl: 56px;
$padding-xxl: 165px;

$gap-xs: 8px;
$gap-s: 16px;
$gap-m: 24px;
$gap-xl: 80px;

@mixin shadow20 {
  box-shadow: 0px 0px 0.5px #303133, 0px 1px 1.5px rgba(48, 49, 51, 0.08);
}

@mixin shadow40 {
  box-shadow: 0px 0px 0.5px #303133, 0px 2px 2px rgba(48, 49, 51, 0.08);
}

@mixin shadow60 {
  box-shadow: 0px 0px 0.5px 0px #303133, 0px 4px 4px 0px rgba(48, 49, 51, 0.08);}

@mixin shadow80 {
  box-shadow: 0px 0px 0.5px #303133, 0px 8px 8px rgba(48, 49, 51, 0.08);
}

@mixin shadow100 {
  box-shadow: 0px 0px 0.5px #303133, 0px 16px 50px rgba(48, 49, 51, 0.08);
}

$missionGradient: linear-gradient(150deg, #B386CF33 0%, #FFFFBC33 100%);
@mixin mission-gradient {
  background: var(--mission-gradient, linear-gradient(108deg, rgba(179, 134, 207, 0.20) 7.09%, rgba(255, 255, 188, 0.20) 94.11%), #F2F4EB);
}

@mixin font-reckless {
  font-family: Reckless TRIAL, 'sans-serif';
}

@mixin font-work-sans {
  font-family: work sans, 'sans-serif';
}

@mixin headline-2-bold {
  font-family: Reckless TRIAL, 'sans-serif';
  font-size: 49px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: -0.01em;
  text-align: center;
}

@mixin lead-bold {
  //styleName: typography/Lead/Bold;
  font-family: Work Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;

}

@mixin body-bold {
  //styleName: typography/Body/Bold;
  font-family: Work Sans;
  font-size: 19px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin body-regular {
  font-family: Work Sans;
  font-size: 19px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;

}

@mixin small-bold {
  //styleName: typography/Small/Bold;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

@mixin small-regular {
  //styleName: typography/Small/Regular;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

}

@mixin xs-regular {
  //styleName: typography/XSmall/Regular;
  font-family: Work Sans;
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin xs-bold {
  //styleName: typography/XSmall/Bold;
  font-family: Work Sans;
  font-size: 11px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin caption-regular {
  font-family: Work Sans, 'sans-serif';
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin caption-bold {
  font-family: Work Sans, 'sans-serif';
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

@mixin button-large-bold {
  //styleName: typography/Button/Large/Bold;
  font-family: Work Sans, 'sans-serif';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: center;
}

@mixin button-large-regular {
  //styleName: typography/Button/Large/Regular;
  font-family: Work Sans, 'sans-serif';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: center;
}

@mixin button-normal-bold {
  //styleName: typography/Button/Normal/Bold;
  font-family: Work Sans, 'sans-serif';
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: center;
}

@mixin input-normal {
  //styleName: typography/Input/Normal/Regular;
  font-family: Work Sans, 'sans-serif';
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

@mixin transition {
  transition-property: all;
  transition-duration: 0.16s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);;
}

@mixin focused {
  outline: 1px dashed $accentEmphasis!important;
}