@import 'src/Styles/Constants.scss';

.settingsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  margin-bottom: 20px;
}

.innerSettingsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;
}

.uploadLogoContainer {
  width: fit-content;
}

.uploadButton {
  border-radius: 15px;
  padding: 50px;
  border: 1px solid $darkGreen;
}

.contactPerson {
  font-weight: 800;
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
  padding-bottom: 15px;
}