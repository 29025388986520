@import 'src/Styles/Constants.scss';

.checkboxRoot {
  all: unset;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid $darkGreen;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkboxIndicator {
  color: $darkGreen;
  cursor: pointer;
}

.label {
  padding-left: 5px;
  padding-bottom: 0;
  cursor: pointer;
}
