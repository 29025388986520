@import '../../../Styles/V2/Constants';

.container {
  display: flex;
  flex-direction: column;
  gap: $gap-m;
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.explainer {
  display: grid;
  gap: $padding-m;
}

.explainerItem {
  gap: $padding-xs;
  display: grid;
  grid-template-columns: 24px 1fr;
  
  > span {
    padding-top: 2px;
  }
}

.toppick {
  background-color: $accentEmphasis;
  color: white;
  border-radius: 100px;
  padding: calc($padding-xs - $borderWidth) $padding-s;
  display: flex;
  justify-content: center;
  align-items: center;
}