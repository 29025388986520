@import 'src/Styles/Constants';

.section {
  padding: 50px 0;
  border: solid $darkGreen;
  border-width: 0 0 1px 0;
}

.padding {
  margin-bottom: 40px;
}

.headLine {
  font-size: 40px;
}

.details > div {

  > * {
    display: inline-block;
    width: 150px;
    padding: 5px 0 ;
  }

  > :first-child {
    font-weight: 600;
    padding-right: 20px;
  }
}

.missingPeriod {
  text-align: start;
  font-weight: bold;
  padding: 5px 0;
  color: $red;
}

.select {
  font-size: 14px;
  background-color: transparent;
  color: $darkGreen;

  width: 100%;

  outline: 0;

  padding: $defaultMargin;

  border: 1px solid $darkGreen;
  border-radius: 5px;
}
