@import 'src/Styles/Constants.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.innerContainer {
  margin-top: clamp(20px, 15vh, 200px);
  width: clamp(450px, 50%, 600px);

  > h1, h2  {
    text-align: center;
  }
}

hr {
  max-width: 30%;
}

