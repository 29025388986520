@import'src/Styles/V2/Constants';


.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $padding-s;

  &.error {
    color: $errorMuted;
  }
}

.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: $padding-xs;
}

$itemSize: 20px;

.item {
  all: unset;
  width: $itemSize;
  height: $itemSize;
  border: $borderWidth $accentDefault solid;
  border-radius: $itemSize;

  &.error {
    border-color: $errorMuted;
  }

  cursor: pointer;
  
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  
  &::after {
    content: '';
    display: block;
    width: calc($itemSize / 1.7);
    height: calc($itemSize / 1.7);
    border-radius: 50%;
    background-color: $accentDefault
  }
}