@import "src/Styles/V2/Constants";

.container {
  flex-direction: column;
  align-items: center;
  display: flex;
  gap: $padding-xl;
}

.grid {
  gap: $padding-xl;
  display: grid;
  grid-template-columns: 1fr 1fr;
}