@import 'src/Styles/Constants.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-gap: 60px;
  margin-top: 50px;
  padding-top: 30px;
  margin-bottom: 50px;
  padding-bottom: 30px;
  width: 100%;
}

.alignCenter {
  width: 100%;
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.padding {
  margin-top: $bigMargin;
}
