@import 'src/Styles/Constants.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 60px;
  margin-top: 50px;
  padding-top: 30px;
}

.alignCenter {
  text-align: center;
}

.column {
  border-radius: 5px;
  border: 1px solid $darkGreen;

  &.recommendedColumn {
    transform: translateY(-30px);
  }
}

.padding {
  padding: $bigMargin;
}

.recommended {
  width: 100%;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid $darkGreen;
  background-color: $purple;

  display: flex;
  justify-content: center;
}

.explainer {
  font-size: 12px;
}

