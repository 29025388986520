@import 'src/Styles/V2/Constants';

.container {
  position: relative;
  display: flex;
  width: 100%;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0, .15);
  pointer-events: none;

  opacity: 0;
  z-index: 99998;

  &.open {
    opacity: 1;
    pointer-events: all;

    > .dropdown {
      opacity: 1;
      pointer-events: all;
    }
  }
  
  > .dropdown {
    position: absolute;
    display: block;

    min-width: 200px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $fgAccent;

    border-radius: 18px;
    white-space: nowrap;
    opacity: 0;
    transform: translateY(-2px);
    transition: opacity 0.09s ease-out, transform 0.12s ease-out;

    pointer-events: none;

    z-index: 99999;
  } 
  
}
