@import 'src/Styles/Constants.scss';

.termsField {
  background-color: #C2C3BC;
  border-radius: $defaultBorderRadius;
  color: black;
  padding: $bigMargin;
  margin-top: $bigMargin;
  margin-bottom: $bigMargin;
  max-height: 400px;
  white-space: pre-wrap;
  overflow: scroll;

  font-family: Inter, sans-serif;
}

.select {
  font-size: 14px;
  background-color: transparent;
  color: $darkGreen;

  width: 100%;

  outline: 0;

  padding: $defaultMargin;

  border: 1px solid $darkGreen;
  border-radius: 5px;
}
