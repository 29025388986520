@import '../../../Styles/V2/Constants';


.explainerItem {
  gap: $padding-xs;
  display: grid;
  grid-template-columns: 24px 1fr;

  > span {
    padding-top: 2px;
  }
}