@import '../../Styles/V2/Constants';

.input {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  max-width: 400px;
  
  > input {
    width: 100%;
    outline: none;
    border: none;
    @include input-normal;
  }
  
  &.disabled {
    background-color: $inputBgDisabled;
  }
  
  background-color: $inputBg;
  color: $inputText;
  
  border: $borderWidth $inputBorder solid;
  border-radius: 6px;
  padding: $padding-xs calc($padding-xs * 1.5);
  
  &.error {
    border-color: $errorMuted;
  }
  
  ::placeholder {
    color: $inputPlaceholder;
  }
}