@import 'src/Styles/Constants.scss';
@import 'src/Styles/Utils';


input[type="file"] {
  display: none;
}

.label {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-transform: UPPERCASE;

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  cursor: pointer;
  color: $darkGreen;
  background-color: transparent;

  margin: 0;

  padding: $defaultMargin $defaultMargin * 3.5;
  border-radius: 100px;
  border: 1px solid $darkGreen;

  &:hover {
    background-color: $darkGreen;
    color: $lightBackground;

    > div > svg > * {
      fill: #FFF;
    }
  }
}

.loader {
  @include transition;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 0;
  overflow: hidden;

  &.visible {
    width: 20px;
    overflow: visible;
  }
}

