@import 'src/Styles/Constants.scss';

$transition: all .5s ease-in-out;

.pageLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $lightBackground;

  transition: $transition;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.content {
  transition: $transition;
  width: 100%;
  height: 100%;
  min-height: 100%;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
