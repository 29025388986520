@import 'src/Styles/Constants.scss';

.post {
  border-radius: 15px;
  border: 1px solid $darkGreen;
  height: 100%;


  > .header {
    padding: 15px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  > .img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    > img {
      border-style: solid;
      border-color: $darkGreen;
      border-width: 1px 0;
      max-width: 100%;
      max-height: 100%;
    }
  }

  > .icons {
    padding: 15px 15px 0 15px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  > .content {

    padding: 15px;

    > textarea {
      background-color: transparent;
      border: none;
      width: 100%;
    }
  }
}

.downloadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  text-transform: uppercase;
}
