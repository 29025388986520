
// Units

$lineWidth: 1px;

$smallMargin: 10px;
$defaultMargin: 15px;
$bigMargin: 20px;

$defaultBorderRadius: 5px;



// Colors

$black: #000;
$darkGreen: #153629;

$lightBackground: #F2F4EB;

$purple: #DEC3EF;

$red: #8B0000;

