.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 40px;
  cursor: pointer;

  > span {
    padding-left: 20px;
    font-size: 40px;
  }
}
