@import 'src/Styles/Constants.scss';

.contentGrid {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.imageGrid {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: repeat(auto-fill, 200px);
}

.imageContainer {
  box-sizing: border-box;
  border-radius: 10px;
  outline: 1px solid $darkGreen;
  cursor: pointer;

  &.selected {
    outline-width: 3px;
  }
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 200px;
  border-radius: 10px;
  box-sizing: border-box;

  > img {
    box-sizing: border-box;

    object-fit: fill;
    border-radius: 10px;
    height: 100%;
    width: 100%;
  }
}

.imageName {
  padding-top: 15px;
}

.downloadContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
