@import 'src/Styles/Constants.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.innerContainer {
  margin-top: clamp(20px, 15vh, 200px);
  max-width: 90%;

  > h1, h2  {
    text-align: center;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  padding-bottom: $defaultMargin;
}

hr {
  max-width: 30%;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 15px;

  > h1 {
    text-align: center;
    max-width: 700px;
  }
}
.select {
  font-size: 14px;
  background-color: transparent;
  color: $darkGreen;

  outline: 0;

  padding: $defaultMargin;

  border: 1px solid $darkGreen;
  border-radius: 5px;
}

