@import './Constants';

h1, .h1 {
  font-family: Reckless, sans-serif;
  font-size: 44px;
  font-weight: 400;
}

h2, .h2 {
  font-family: Reckless, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.label {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  margin-left: $defaultMargin;
  padding-bottom: $smallMargin;

  &.required:after {
    content: '  *'
  }
}

a {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: $darkGreen;
  text-decoration: underline;
}

.linkExplain {
  font-size: 14px;
  padding: 10px 0;
}
