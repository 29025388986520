@import 'src/Styles/V2/Constants.scss';

.container {
  width: 100vw;
  background: $bgCanvas;
  display: flex;
  justify-content: center;
  
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $padding-l;
  }
}

.logo {
  display: flex;
  align-items: center;
  
  gap: $padding-m;
}

.content {
  display: flex;
  align-items: center;
}
