@import '../../../Styles/V2/Constants';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $padding-l;

  > * {
    max-width: 840px;
  }
}

.headline {
  padding-top: $padding-l;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: $padding-s;
}


.agreementArea {
  border-radius: 10px;
  max-height: 400px;
  white-space: pre-wrap;
  overflow-x: scroll;
  border: $borderWidth solid $borderColor;
  padding: $padding-xs;
  margin-bottom: $padding-m;

  @include input-normal
}