@import 'src/Styles/V2/Constants';

.tooltip {
  background-color: #0D2019;
  color: $accentDisabled;
  border-radius: 8px;
  padding: $padding-s $padding-m;
  max-width: 250px;

  @include caption-regular;
}