@import '../../Styles/V2/Constants';

.button {
  outline: none;
  padding: calc($padding-xs - $borderWidth) $padding-s;
  border-radius: 100px;
  border: $borderWidth transparent solid;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  
  cursor: pointer;

  @include button-large-bold;

  &:disabled {
    cursor: not-allowed;
  }
  
  &.outline {
    &.primary {
      background-color: transparent;
      color: $accentDefault;
      border: $borderWidth $accentDefault solid;
    }

    &.secondary {
      background-color: transparent;
      color: $accentEmphasis;
      border: $borderWidth $accentEmphasis solid;
    }
  }
  
  &:not(.outline) {
    &.primary {
      background-color: $accentDefault;
      border: $borderWidth $accentDefault solid;
      color: $fgAccent;

      &:disabled {
        background-color: $accentDisabled;
        color: $fgDisabled;
        border: $borderWidth $accentDisabled solid;
      }
    }

    &.secondary {
      background-color: $accentEmphasis;
      border: $borderWidth $accentEmphasis solid;
      color: $fgAccent;

      &:disabled {
        background-color: $accentDisabled;
        color: $fgDisabled;
        border: $borderWidth $accentDisabled solid;

      }
    }
  }
  
}

.spinner {
  &.outline {
    &.primary {
      fill: $accentDefault;
    }
  }

  &:not(.outline) {
    &.primary {
      fill: $accentDefault;
    }
  }
}

.icon {
  @include transition;
  overflow: hidden;
  
  width: 0;
  height: 16px;
  
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.active {
    margin-right: $gap-xs;
    width: 16px;
  }
}

.spin {
  animation-name: spin;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
