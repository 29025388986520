@import 'src/Styles/Constants.scss';
@import 'src/Styles/Utils';

.button {
  @include transition;

  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-transform: UPPERCASE;

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  cursor: pointer;
  color: $darkGreen;
  background-color: transparent;

  &.default {
    padding: $defaultMargin $defaultMargin * 3.5;
    border-radius: 100px;
    border: 1px solid $darkGreen;

    &:hover {
      background-color: $darkGreen;
      color: $lightBackground;

      > div > svg > * {
        fill: #FFF;
      }
    }
  }

  &.danger {
    color: $red;
    padding: $defaultMargin $defaultMargin * 3.5;
    border-radius: 100px;
    border: 1px solid $red;

    &:hover {
      background-color: $red;
      color: $lightBackground;

      > div > svg > * {
        fill: #FFF;
      }
    }
  }

  &.link {
    border: none;
    text-decoration: underline;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }


}

.loader {
  @include transition;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 0;
  overflow: hidden;

  &.visible {
    width: 20px;
    overflow: visible;
  }
}
