@import '/src/Styles/V2/Constants';

.gap {
  display: flex;
  
  &.block {
    width: 100%;
  }
  
  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }
  
  &.horizontal {
    flex-direction: row;
    align-items: center;
  }
  
  &.small {
    gap: $padding-xs;
  }

  &.medium {
    gap: $padding-m;
  }

  &.large {
    gap: $padding-l;
  }
}