@import '/src/Styles/Constants';

$blockSize: 800px;
$blockSizePlus1 : calc(#{$blockSize} + 1px);

$separatorPosition: 20px;

$borderRadius: 3px;
$defaultBorderColor: $darkGreen;
$defaultBorderWidth: 1px;
$columnBorder: 1px solid $defaultBorderColor;

.container {
  width: 100%;
  display: grid;
  border-color: $darkGreen;
  border-style: solid;
  border-width: 1px 0px 1px 1px;

}

.row {
  display: contents;

  border-color: $darkGreen;
}

.column {
  padding: 20px 10px;
  border-width: 1px 1px 0 0;
  border-color: $darkGreen;
  border-style: solid;
}

.title {
  border-width: 0px 1px 0 0;
  border-color: $darkGreen;
  border-style: solid;
  font-weight: bold;
  margin-left: 0px!important;
  padding: 20px 10px 20px 10px!important;
}
