@import 'src/Styles/Constants.scss';

.space {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: $defaultMargin;

  &.small {
    gap: $smallMargin;
  }

  &.large {
    gap: $bigMargin;
  }
}
