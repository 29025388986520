@import 'src/Styles/Constants.scss';

.contactArea {
  background-color: #C2C3BC;
  border-radius: $defaultBorderRadius;
  color: black;
  padding: $bigMargin;
  margin-top: $bigMargin;
  margin-bottom: $bigMargin;
  max-height: 400px;
  white-space: pre-wrap;
  overflow: scroll;

  font-family: Inter, sans-serif;
}
