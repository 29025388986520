@import '/src/Styles/Constants';

.hr {
  width: 100%;
  padding: 20px 0;
}

.authorizationItem {

  display: flex;
  justify-content: space-between;

  > div:first-child {
    font-size: 22px;
  }

  > .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  padding: 15px;
  margin: 15px 0;
  border: 1px solid $darkGreen;
}

.accordion {

  > .item {
    margin: 10px 0;
    cursor: pointer;

    > .title {
      padding: 10px;
      font-size: 26px;
      text-transform: uppercase;
      font-family: "Inter", sans-serif;
    }

    > .content {
      overflow: hidden;
      max-height: 0;
      padding: 0 10px;
      line-height: 1.3;
      font-size: 26px;

      transition: all 0.2s ease;
    }

    &.open {
      > .content {
        max-height: 250px;
        padding: 10px;
      }

      > .title {
        border-bottom: 1px solid $darkGreen;
      }
    }
  }
}
