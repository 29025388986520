.flex {
  display: flex;

  &.justify-center {
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.column {
    flex-direction: column;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.wrap {
    flex-wrap: wrap;
    width: 100%;
  }
}

.w-100 {
  width: 100%
}

.h-100 {
  height: 100%;
}

.wh-100 {
  height: 100vh;
}

.w-50 {
  width: 50%
}

.h-50 {
  height: 50%;
}

.clickable {
  cursor: pointer;
}

.text-align-center {
  text-align: center;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.display-linebreak {
  white-space: pre-line;
}

@mixin transition {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);;
}
