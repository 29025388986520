@import '../../../Styles/V2/Constants';

.agreementArea {
  border-radius: 10px;
  max-height: 400px;
  white-space: pre-wrap;
  overflow: scroll;
  border: $borderWidth solid $borderColor;
  padding: $padding-xs;

  @include input-normal
}