@import 'Styles/V2/Constants';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.9);
  backdrop-filter: blur($gap-xs);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  transition: all 0.15s ease-in-out;
  pointer-events: none;
  opacity: 0;

  &.enterDone {
    opacity: 1;
    pointer-events: visible;
  }

  &.exit {
    opacity: 0;
  }

  > .content {
    margin-top: 120px;
    width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    padding: $padding-m $padding-xl;

    background-color: $bgSurface;
    border-radius: 24px;
    border-color: $borderColor;
    border-width: $borderWidth;

    @include shadow60;
    transition: all 0.15s ease-in-out;

    transform: none;

    position: relative;

    > .close {
      cursor: pointer;
      position: absolute;
      top: $gap-xs;
      right: $gap-xs;
    }
  }
}

.header {
  display: flex;
  justify-content: center;

  > * {
    max-width: 80%;
  }
}

.body {
  padding: 40px 40px 40px 40px;
}
