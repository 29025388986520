@import 'Styles/Utils';
@import 'Styles/Constants';
@import 'Styles/text';

/* width */
::-webkit-scrollbar {
  width: 3px;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #99a0ac;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  min-height: 100vh;
  overflow: scroll;
  font-family: "Reckless", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  overflow-x: hidden;
  background-color: #F2F4EB !important;
  color: #153629;
}

*, :after, :before {
  box-sizing: border-box;
  font-style: normal;
  font-size: 14px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

:root {
  --toastify-color-success: blue;
}

.calendlyOutlineButton {
  color: #445E54;
  border: 2px solid #445E54;
  border-radius: 100px;

  background-color: transparent;
  cursor: pointer;


  //styleName: typography/Button/Large/Bold;
  font-family: Work Sans, 'sans-serif';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: center;
  padding: 8px 16px;
}
