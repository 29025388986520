@import 'src/Styles/Constants.scss';

.container {
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
}


.slide {
  width: 100%;

  &:not(.active) {
    display: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 100%;
  }
}

.remove {
  position: absolute;
  top: 10px;
  right: 10px;
}


/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

.dotContainer {
  position: absolute;
  bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #bbb;

  cursor: pointer;

  &.active {
    background-color: $darkGreen;
  }
}
