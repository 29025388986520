@import 'src/Styles/Constants.scss';

$sectionPadding: 80px;

@media only screen and (min-width: 1250px) {
  .graphGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 700px) {
  .graphGrid {
    grid-template-columns: 1fr;
  }
}

.graphGrid {
  display: grid;

  gap: 30px;

  padding: 0 0 $sectionPadding 0;


  > div {

    &:first-child {
      border-right: 1px solid $black;
    }
  }
}


.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 250px));

  border-width: 1px 0;
  border-style: solid;
  border-color: #000;
  padding: $sectionPadding 0;

  gap: 60px;

  > .stat {
    aspect-ratio: 1;
    border-radius: 15px;
    border: 1px solid $darkGreen;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;

    > :first-child {
      font-size: 40px;
      padding-bottom: 30px;
      text-align: center;
    }

    > :last-child {
      font-family: "Inter", sans-serif;
      text-transform: uppercase;
      text-align: center;
    }
  }
}

.statTitle {
  font-size: 40px;
  padding-bottom: 30px;
}

.resources {
  padding: $sectionPadding 0;

  > div {
    font-size: 40px;
    padding-bottom: 30px;
  }
}

$resourceWidth: 250px;
$resourcePadding: 20px;

.resourcesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, $resourceWidth);
  gap: 30px;
}

.content {
  width: calc($resourceWidth - $resourcePadding * 2);
  display: flex;
  justify-content: center;
  align-items: center;
}
