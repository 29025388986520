@import 'src/Styles/Constants.scss';

$border: 1px solid $black;
$margin: 0 40px;

.container {
  display: grid;
  max-width: 1440px;
  min-height: 100vh;
  margin: auto;
  grid-template-rows: 150px fit-content(50px) 1fr fit-content(300px);
}

.header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  color: $darkGreen;

  padding: 30px;

  margin: $margin;
  border-bottom: $border;

  > .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  > .end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

}

.preHeader {
  font-size: 20px;
  padding-bottom: 10px;
}

.heroHeader {
  font-size: 40px;
  font-weight: 700;
}

.menu {
  margin: $margin;
  border-bottom: $border;
  padding: 10px 15px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 15px;

    > * {
        font-size: 15px;
        text-transform: uppercase;
        font-family: "Inter", sans-serif;
      }
  }
}

.content {
  margin: $margin;
  height: fit-content;
  padding: 15px 0 40px 0;
}

.footer {
  border-top: $border;
  border-bottom: $border;
  margin: $margin;
  margin-bottom: 20px;
  padding: 60px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  > :last-child > div {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    font-weight: 600;
  }
}

