@import 'src/Styles/Constants.scss';

.image {
  padding: 10px;
  border-radius: 15px;
  border: 1px solid $darkGreen;
  display: grid;
  grid-template-columns: repeat(auto-fill, auto);

  position: relative;

  > img {
    border-radius: 3px;
  }

  > .remove {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
