@import 'src/Styles/Constants.scss';

.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  padding-bottom: $defaultMargin;
}
