@import 'src/Styles/Constants.scss';

.input {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  background-color: transparent;
  color: $darkGreen;

  width: 100%;

  outline: 0;

  padding: $defaultMargin;

  border: 1px solid $darkGreen;
  border-radius: 5px;

  &::placeholder {
    color: rgba($darkGreen, 40%);
  }

  &.errors {
    border-color: red;
  }
}
