@import 'src/Styles/Constants.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.post {
  border-radius: 15px;
  border: 1px solid $darkGreen;
  margin: 30px 0;

  > .header {
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  > .img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-width: 1px 0;
    border-style: solid;
    border-color: $darkGreen;
    margin-bottom: 10px;
    position: relative;

    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  > .icons {
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  > .content {

    padding: 10px;

    > textarea {
      background-color: transparent;
      border: none;
      width: 100%;
    }

  }
}

.remove {
  position: absolute;
  top: 10px;
  right: 10px;
}
