@import 'src/Styles/Constants.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.innerContainer {
  margin-top: clamp(20px, 15vh, 200px);
  width: 100%;
}
